<template>
  <div>
    <div ref="map" id="map"></div>
    <!-- <div ref="map" :style="{ height: mapHeight, width: mapWidth }" id="map"></div> -->

    <button ref="toggleButton" @click="toggleTable" class="toggle-button">
      {{ getButtonText() }}
    </button>
    <div
      ref="tableContainer"
      class="table-container"
      :class="{ hidden: !isTableVisible }"
      :style="{ width: tableWidth }"
    >
      <table v-show="isTableVisible">
        <thead>
          <tr>
            <th>Flight</th>
            <th>Altitude (ft)</th>
            <th>Speed (kts)</th>
            <th>Track Heading (°)</th>
            <th style="display: none;">Hex Ident</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="flight in flights"
            :key="flight.callsign"
            :class="{ 'highlighted-row': flight.hex_ident.trim() === selectedFlightIndex }"
          >
            <td>{{ flight.callsign.trim() }}</td>
            <td>{{ flight.altitude }}</td>
            <td>{{ flight.ground_speed }}</td>
            <td>{{ flight.track }}</td>
            <td style="display: none;">{{ flight.hex_ident.trim() }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
  
  <script>
import axios from "../axiosConfig.js";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import airplaneIcon from "@/assets/airplane.svg";

export default {
  data() {
    return {
      flights: [],
      selectedFlightIndex: -1,
      index: -1,
      map: null,
      markers: [],
      isTableVisible: false,
      mapHeight: "100vh",
      mapWidth: "75%",
      tableWidth: "25%",
      tracks: {},
      activeTrack: null,
    };
  },
  mounted() {
    setTimeout(() => {
      this.initializeMap();
      this.fetchFlightData();
      setInterval(() => {
        this.fetchFlightData();
        this.updateWidths();
      }, 5000);
      setInterval(() => {
        this.updateWidths();
      }, 1000);
      setInterval(() => {
        this.fetchTrackData(this.activeTrack);
      }, 5000);
    }, 0);
  },

  methods: {
    initializeMap() {
      this.map = L.map(this.$refs.map).setView([-32.9267, 151.7789], 10);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "© OpenStreetMap contributors",
      }).addTo(this.map);
      this.map.on("zoomend", this.handleZoom);
    },
    getButtonText() {
      return this.isTableVisible ? "Hide Flights" : "Show Flights";
    },
    fetchFlightData() {
      axios
        .get("position")
        .then((response) => {
          this.flights = response.data;
          this.displayMarkers();
        })
        .catch((error) => {
          console.error("Error fetching flight data:", error);
        });
    },
    displayMarkers() {
      if (!this.flights) {
        this.markers.forEach((marker) => marker.remove());
        Object.values(this.tracks).forEach((track) => {
          track.remove();
          console.log("track remove :", track);
          this.removeTrackFromMap(track);
          this.selectedFlightIndex = -1;
          this.flight = null;
          this.activeTrack = null;
          this.flights = [];
        });
        // Object.values(this.tracks).forEach((track) => track.remove());
        this.markers = [];
        this.tracks = {};
        console.warn("Flights data is null or undefined.");
        return;
      }
      this.markers.forEach((marker) => marker.remove());
      const markerMap = new Map();
      // Remove markers and tracks for planes that have disappeared
      const existingCallsigns = this.flights.map((flight) => flight.callsign);

      this.markers.forEach((marker) => {
        if (!existingCallsigns.includes(marker.options.callsign)) {
          marker.remove();
          if (this.tracks[marker.options.callsign]) {
            this.tracks[marker.options.callsign].remove();
            delete this.tracks[marker.options.callsign];
          }
        }
      });
      this.flights.forEach((flight, index) => {
        if (
          typeof flight.latitude === "number" &&
          typeof flight.longitude === "number"
        ) {
          const key = `${flight.latitude},${flight.longitude}`;
          const popupContent = `<b>${flight.callsign}</b><pre>ICAO: ${flight.hex_ident}\nAltitude: ${flight.altitude} ft\nSpeed: ${flight.ground_speed} kts\nHeading: ${flight.track}°</pre>`;

          const marker = L.marker([flight.latitude, flight.longitude], {
            icon: this.createCustomIcon(flight),
          })
            .bindTooltip(popupContent, {
              permanent: false,
              direction: "right",
            })
            .addTo(this.map);

          markerMap.set(key, marker);

          // Fetch track data for each flight asynchronously
          axios
            .get(`/track/${flight.hex_ident}`)
            .then((response) => {
              const trackData = response.data;
              if (trackData && trackData.heading) {
                // Update the popup content with track data
                const updatedPopupContent = `<b>${flight.callsign}</b><pre>ICAO: ${flight.hex_ident}\nAltitude: ${flight.altitude} ft\nSpeed: ${flight.ground_speed} kts\nHeading: ${trackData.heading}°</pre>`;
                marker.setPopupContent(updatedPopupContent);
              }
              //  else {
              //   console.warn("Invalid track data received for flight:", flight);
              // }
            })
            .catch((error) => {
              console.error(
                "Error fetching track data for flight:",
                flight,
                error
              );
            });

          // Toggle track on marker click
          marker.on("click", () => {
            if (marker.getPopup()) {
              marker.getPopup().options.permanent = true;
            }
            this.toggleTrack(flight, index);
          });
        } else {
          console.warn(
            `Invalid latitude or longitude values for flight with callsign ${flight.callsign}:`,
            flight.latitude,
            flight.longitude
          );
        }
      });
      this.markers = Array.from(markerMap.values());
    },

    createCustomIcon(flight) {
      const rotationAngle = flight.track - 45;
      const zoomLevel = this.map.getZoom();
      const iconSize = zoomLevel >= 12 ? [32, 32] : [16, 16];
      const iconAnchor = zoomLevel >= 12 ? [16, 16] : [8, 8];

      const customIcon = L.divIcon({
        className: "custom-icon",
        html: `<img src="${airplaneIcon}" alt="Airplane Icon" width="${iconSize[0]}}" height="${iconSize[1]}}" style="transform: rotate(${rotationAngle}deg);">`,
        iconSize: iconSize,
        iconAnchor: iconAnchor,
      });

      return customIcon;
    },
    handleZoom() {
      if (this.flights && this.flights.length > 0) {
        this.displayMarkers();
      } else {
        this.map.setZoom(this.map.getZoom());
      }
    },
    toggleTable() {
      this.isTableVisible = !this.isTableVisible;

      this.$nextTick(() => {
        const dummyClass = this.isTableVisible ? "show" : "hide";
        this.$refs.map.classList.add(dummyClass);
        this.$refs.map.offsetHeight; // Trigger reflow
        this.$refs.map.classList.remove(dummyClass);
      });
    },
    updateWidths() {
      if (!this.$refs.tableContainer) {
        return; // Return early if tableContainer is undefined or null
      }
      this.mapWidth = this.isTableVisible ? "75%" : "100%";
      this.tableWidth = this.isTableVisible ? "25%" : "0%";
      if (!this.isTableVisible) {
        this.mapWidth = "100%"; // Expand the map to fill the entire width when the table is hidden
      }

      this.$nextTick(() => {
        const button = this.$refs.toggleButton;
        if (button) {
          button.style.zIndex = this.isTableVisible ? 100 : 4;
        }
      });

      const rows = this.$el.querySelectorAll("tr"); // Get all table rows
      let totalHeight = 0;
      rows.forEach((row) => {
        totalHeight += row.clientHeight; // Sum up the heights of all rows
      });
      this.$refs.tableContainer.style.height = `${totalHeight + 42}px`;

      if (this.map) {
        this.map.invalidateSize();
      }
    },

    toggleTrack(flight) {
  const key = `${flight.callsign.trim()}_${flight.hex_ident.trim()}`;
  if (this.activeTrack === key) {
    this.removeTrackFromMap();
    this.activeTrack = null;
    this.selectedFlightIndex = null;
    // Remove all other tracks
    Object.keys(this.tracks).forEach((trackKey) => {
      if (trackKey !== key) {
        this.removeTrackFromMap(trackKey);
      }
    });
  } else {
    if (!this.activeTrack) {
      this.selectedFlightIndex = null;
    }
    this.selectedFlightIndex = flight.hex_ident.trim();
    // Remove all other tracks
    Object.keys(this.tracks).forEach((trackKey) => {
      if (trackKey !== key) {
        this.removeTrackFromMap(trackKey);
      }
    });
    this.activeTrack = key;
    this.fetchTrackData(flight);
    this.map.panTo([flight.latitude, flight.longitude]);
  }
},


    toggleTrack1(flight, index) {
      const key = `${flight.callsign.trim()}_${flight.hex_ident.trim()}`;
      if (this.activeTrack === key) {
        // console.log("Removing track");
        this.removeTrackFromMap();
        this.activeTrack = null;
        this.selectedFlightIndex = -1;
        // Remove all other tracks
        Object.keys(this.tracks).forEach((trackKey) => {
          if (trackKey !== key) {
            this.removeTrackFromMap(trackKey);
          }
        });
      } else {
        // console.log("Adding track");

        if (!this.activeTrack) {
          this.selectedFlightIndex = -1;
        }
        this.selectedFlightIndex = -1;
        // Remove all other tracks
        Object.keys(this.tracks).forEach((trackKey) => {
          if (trackKey !== key) {
            this.removeTrackFromMap(trackKey);
          }
        });
        this.activeTrack = key;
        this.fetchTrackData(flight);
        this.map.panTo([flight.latitude, flight.longitude]);
        this.selectedFlightIndex = index;

        // console.log("Index :::", index);
        // console.log("flightindex :::", this.selectedFlightIndex);
      }
    },
    removeTrackFromMap() {
      if (this.tracks[this.activeTrack]) {
        this.map.removeLayer(this.tracks[this.activeTrack]);
        delete this.tracks[this.activeTrack];
      }
    },
    fetchTrackData() {
      if (!this.activeTrack) {
        return;
      }
      // console.log("Flight :::", flight);
      // console.log("activeTrack :::", this.activeTrack);
      const keyParts = this.activeTrack.split("_");
      const reversedKey = {
        callsign: keyParts[0].trim(),
        hex_ident: keyParts[1].trim(),
      };

      axios
        .get(
          `/points/${reversedKey.callsign
            .substring(0, 3)
            .toLowerCase()}/${reversedKey.hex_ident.trim().toLowerCase()}`
        )
        .then((response) => {
          if (response.data) {
            const points = response.data.map((point) =>
              L.latLng(point[0], point[1])
            );
            const reprojectedPoints = points.map((point) => point.wrap());
            this.createPolyline(reprojectedPoints);
          } else {
            this.removeTrackFromMap();
          }
        })
        .catch((error) => {
          console.error("Error fetching track data:", error);
        });
    },
    createPolyline(points) {
      if (this.tracks[this.activeTrack]) {
        this.map.removeLayer(this.tracks[this.activeTrack]);
      }
      // console.log('Points :',points)
      const polyline = L.polyline(points, { color: "green" }).addTo(this.map);
      this.tracks[this.activeTrack] = polyline;
    },
  },
};
</script>
  
  <style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 40px;
  z-index: 1;
  height: auto;
  font-size: 1vw;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  height: 10px;
  font-size: 1vw;
  border-radius: 10px;
}
button {
  margin-bottom: 10px;
  z-index: 2;
}
.toggle-button {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
}
.highlighted-row {
  background-color: greenyellow;
}
.table-container {
  background-color: white;
  width: 100%;
  float: right;
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  transition: transform 0.5s ease; /* Change width to transform */
  transform: translateX(0); /* Default position */
  border-radius: 10px; /* Add rounded corners */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.table-container.hidden {
  transform: translateX(100%); /* Move it out of view */
}
.hidden {
  display: none;
}
#map {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 0;
}
</style>
  