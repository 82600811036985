<template>
  <div>
    <flight-map />
  </div>
</template>

<script>
import FlightMap from '@/components/FlightMap.vue';

export default {
  components: {
    FlightMap,
  },
};
</script>
