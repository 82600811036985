import axios from 'axios';
const baseURL =(() =>{
    const hostname =window.location.hostname;
    const proto = window.location.protocol;
    const port = window.location.port;
    return `${proto}//${hostname}:${port}/api/adsb`;
})();
const instance = axios.create({
    baseURL,
});
export default instance;